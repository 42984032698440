
import { getSliceComponentProps } from '@prismicio/vue/components'
import Swiper from 'swiper/swiper-bundle.min'
import 'swiper/swiper-bundle.min.css'

export default {
  name: 'TestimonialsSlice',
  // The array passed to `getSliceComponentProps` is purely optional and acts as a visual hint for you
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  async mounted() {
    await this.$nextTick()

    // init Swiper:
    /* eslint-disable no-unused-vars */
    const swiper = new Swiper(this.$refs.swiper, {
      speed: 2000,
      spaceBetween: 1200,
      direction: 'horizontal',
      loop: true,
      // remove unused modules if needed
      // Autoplay if needed
      autoplay: {
        delay: 3000,
      },
      // Pagination if needed
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      // Configure other options. Not tested
    })
  },
  methods: {
    htmlSerializer(type, element, text, children) {
      if (type === 'span') return `<p>&ldquo;${text}&rdquo;</p>`
      return null
    },
  },
}
